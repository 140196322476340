.login{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 200px;
        height: 30px;
        border: none;
        background-color: red;
        color: white;
        font-weight: bold;
        cursor: pointer;
    }
}