.list {
  display: flex;
  width: 100%;
  .listContainer {
    flex: 6;
  }
}


.production {
  background-color: #a4fb9c;
}

.produced {
  background-color: #fc8f02;
}

.loaded {
  background-color: #9bf1f7;
}

.arrived {
  background-color: #f3f99e;
}